/**
 * @fileoverview gRPC-Web generated client stub for order
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.4.2
// 	protoc              v3.21.12
// source: protobuf/order/order.proto


/* eslint-disable */
// @ts-nocheck


import * as grpcWeb from 'grpc-web';

import * as protobuf_history_history_pb from '../../protobuf/history/history_pb';
import * as protobuf_model_merged_pb from '../../protobuf/model/merged_pb';
import * as protobuf_order_order_pb from '../../protobuf/order/order_pb';


export class OrderServiceClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: any; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: any; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options['format'] = 'binary';

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname.replace(/\/+$/, '');
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodDescriptorCreate = new grpcWeb.MethodDescriptor(
    '/order.OrderService/Create',
    grpcWeb.MethodType.UNARY,
    protobuf_order_order_pb.CreateOrderRequest,
    protobuf_model_merged_pb.OrderResponse,
    (request: protobuf_order_order_pb.CreateOrderRequest) => {
      return request.serializeBinary();
    },
    protobuf_model_merged_pb.OrderResponse.deserializeBinary
  );

  create(
    request: protobuf_order_order_pb.CreateOrderRequest,
    metadata: grpcWeb.Metadata | null): Promise<protobuf_model_merged_pb.OrderResponse>;

  create(
    request: protobuf_order_order_pb.CreateOrderRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: protobuf_model_merged_pb.OrderResponse) => void): grpcWeb.ClientReadableStream<protobuf_model_merged_pb.OrderResponse>;

  create(
    request: protobuf_order_order_pb.CreateOrderRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: protobuf_model_merged_pb.OrderResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/order.OrderService/Create',
        request,
        metadata || {},
        this.methodDescriptorCreate,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/order.OrderService/Create',
    request,
    metadata || {},
    this.methodDescriptorCreate);
  }

  methodDescriptorUpdate = new grpcWeb.MethodDescriptor(
    '/order.OrderService/Update',
    grpcWeb.MethodType.UNARY,
    protobuf_order_order_pb.UpdateOrderRequest,
    protobuf_model_merged_pb.OrderResponse,
    (request: protobuf_order_order_pb.UpdateOrderRequest) => {
      return request.serializeBinary();
    },
    protobuf_model_merged_pb.OrderResponse.deserializeBinary
  );

  update(
    request: protobuf_order_order_pb.UpdateOrderRequest,
    metadata: grpcWeb.Metadata | null): Promise<protobuf_model_merged_pb.OrderResponse>;

  update(
    request: protobuf_order_order_pb.UpdateOrderRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: protobuf_model_merged_pb.OrderResponse) => void): grpcWeb.ClientReadableStream<protobuf_model_merged_pb.OrderResponse>;

  update(
    request: protobuf_order_order_pb.UpdateOrderRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: protobuf_model_merged_pb.OrderResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/order.OrderService/Update',
        request,
        metadata || {},
        this.methodDescriptorUpdate,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/order.OrderService/Update',
    request,
    metadata || {},
    this.methodDescriptorUpdate);
  }

  methodDescriptorGet = new grpcWeb.MethodDescriptor(
    '/order.OrderService/Get',
    grpcWeb.MethodType.UNARY,
    protobuf_order_order_pb.GetOrdersRequest,
    protobuf_order_order_pb.GetOrdersResponse,
    (request: protobuf_order_order_pb.GetOrdersRequest) => {
      return request.serializeBinary();
    },
    protobuf_order_order_pb.GetOrdersResponse.deserializeBinary
  );

  get(
    request: protobuf_order_order_pb.GetOrdersRequest,
    metadata: grpcWeb.Metadata | null): Promise<protobuf_order_order_pb.GetOrdersResponse>;

  get(
    request: protobuf_order_order_pb.GetOrdersRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: protobuf_order_order_pb.GetOrdersResponse) => void): grpcWeb.ClientReadableStream<protobuf_order_order_pb.GetOrdersResponse>;

  get(
    request: protobuf_order_order_pb.GetOrdersRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: protobuf_order_order_pb.GetOrdersResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/order.OrderService/Get',
        request,
        metadata || {},
        this.methodDescriptorGet,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/order.OrderService/Get',
    request,
    metadata || {},
    this.methodDescriptorGet);
  }

  methodDescriptorUpdateClientResponsePrice = new grpcWeb.MethodDescriptor(
    '/order.OrderService/UpdateClientResponsePrice',
    grpcWeb.MethodType.UNARY,
    protobuf_order_order_pb.UpdateClientResponsePriceRequest,
    protobuf_model_merged_pb.OrderResponse,
    (request: protobuf_order_order_pb.UpdateClientResponsePriceRequest) => {
      return request.serializeBinary();
    },
    protobuf_model_merged_pb.OrderResponse.deserializeBinary
  );

  updateClientResponsePrice(
    request: protobuf_order_order_pb.UpdateClientResponsePriceRequest,
    metadata: grpcWeb.Metadata | null): Promise<protobuf_model_merged_pb.OrderResponse>;

  updateClientResponsePrice(
    request: protobuf_order_order_pb.UpdateClientResponsePriceRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: protobuf_model_merged_pb.OrderResponse) => void): grpcWeb.ClientReadableStream<protobuf_model_merged_pb.OrderResponse>;

  updateClientResponsePrice(
    request: protobuf_order_order_pb.UpdateClientResponsePriceRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: protobuf_model_merged_pb.OrderResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/order.OrderService/UpdateClientResponsePrice',
        request,
        metadata || {},
        this.methodDescriptorUpdateClientResponsePrice,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/order.OrderService/UpdateClientResponsePrice',
    request,
    metadata || {},
    this.methodDescriptorUpdateClientResponsePrice);
  }

  methodDescriptorUpdateClientRequestedPrice = new grpcWeb.MethodDescriptor(
    '/order.OrderService/UpdateClientRequestedPrice',
    grpcWeb.MethodType.UNARY,
    protobuf_order_order_pb.UpdateClientRequestedPriceRequest,
    protobuf_model_merged_pb.OrderResponse,
    (request: protobuf_order_order_pb.UpdateClientRequestedPriceRequest) => {
      return request.serializeBinary();
    },
    protobuf_model_merged_pb.OrderResponse.deserializeBinary
  );

  updateClientRequestedPrice(
    request: protobuf_order_order_pb.UpdateClientRequestedPriceRequest,
    metadata: grpcWeb.Metadata | null): Promise<protobuf_model_merged_pb.OrderResponse>;

  updateClientRequestedPrice(
    request: protobuf_order_order_pb.UpdateClientRequestedPriceRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: protobuf_model_merged_pb.OrderResponse) => void): grpcWeb.ClientReadableStream<protobuf_model_merged_pb.OrderResponse>;

  updateClientRequestedPrice(
    request: protobuf_order_order_pb.UpdateClientRequestedPriceRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: protobuf_model_merged_pb.OrderResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/order.OrderService/UpdateClientRequestedPrice',
        request,
        metadata || {},
        this.methodDescriptorUpdateClientRequestedPrice,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/order.OrderService/UpdateClientRequestedPrice',
    request,
    metadata || {},
    this.methodDescriptorUpdateClientRequestedPrice);
  }

  methodDescriptorUpsertCargoItems = new grpcWeb.MethodDescriptor(
    '/order.OrderService/UpsertCargoItems',
    grpcWeb.MethodType.UNARY,
    protobuf_order_order_pb.UpsertCargoItemsRequest,
    protobuf_model_merged_pb.OrderResponse,
    (request: protobuf_order_order_pb.UpsertCargoItemsRequest) => {
      return request.serializeBinary();
    },
    protobuf_model_merged_pb.OrderResponse.deserializeBinary
  );

  upsertCargoItems(
    request: protobuf_order_order_pb.UpsertCargoItemsRequest,
    metadata: grpcWeb.Metadata | null): Promise<protobuf_model_merged_pb.OrderResponse>;

  upsertCargoItems(
    request: protobuf_order_order_pb.UpsertCargoItemsRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: protobuf_model_merged_pb.OrderResponse) => void): grpcWeb.ClientReadableStream<protobuf_model_merged_pb.OrderResponse>;

  upsertCargoItems(
    request: protobuf_order_order_pb.UpsertCargoItemsRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: protobuf_model_merged_pb.OrderResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/order.OrderService/UpsertCargoItems',
        request,
        metadata || {},
        this.methodDescriptorUpsertCargoItems,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/order.OrderService/UpsertCargoItems',
    request,
    metadata || {},
    this.methodDescriptorUpsertCargoItems);
  }

  methodDescriptorList = new grpcWeb.MethodDescriptor(
    '/order.OrderService/List',
    grpcWeb.MethodType.UNARY,
    protobuf_order_order_pb.ListOrdersRequest,
    protobuf_order_order_pb.ListOrdersResponse,
    (request: protobuf_order_order_pb.ListOrdersRequest) => {
      return request.serializeBinary();
    },
    protobuf_order_order_pb.ListOrdersResponse.deserializeBinary
  );

  list(
    request: protobuf_order_order_pb.ListOrdersRequest,
    metadata: grpcWeb.Metadata | null): Promise<protobuf_order_order_pb.ListOrdersResponse>;

  list(
    request: protobuf_order_order_pb.ListOrdersRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: protobuf_order_order_pb.ListOrdersResponse) => void): grpcWeb.ClientReadableStream<protobuf_order_order_pb.ListOrdersResponse>;

  list(
    request: protobuf_order_order_pb.ListOrdersRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: protobuf_order_order_pb.ListOrdersResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/order.OrderService/List',
        request,
        metadata || {},
        this.methodDescriptorList,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/order.OrderService/List',
    request,
    metadata || {},
    this.methodDescriptorList);
  }

  methodDescriptorExport = new grpcWeb.MethodDescriptor(
    '/order.OrderService/Export',
    grpcWeb.MethodType.UNARY,
    protobuf_order_order_pb.ExportOrdersRequest,
    protobuf_order_order_pb.ExportOrdersResponse,
    (request: protobuf_order_order_pb.ExportOrdersRequest) => {
      return request.serializeBinary();
    },
    protobuf_order_order_pb.ExportOrdersResponse.deserializeBinary
  );

  export(
    request: protobuf_order_order_pb.ExportOrdersRequest,
    metadata: grpcWeb.Metadata | null): Promise<protobuf_order_order_pb.ExportOrdersResponse>;

  export(
    request: protobuf_order_order_pb.ExportOrdersRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: protobuf_order_order_pb.ExportOrdersResponse) => void): grpcWeb.ClientReadableStream<protobuf_order_order_pb.ExportOrdersResponse>;

  export(
    request: protobuf_order_order_pb.ExportOrdersRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: protobuf_order_order_pb.ExportOrdersResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/order.OrderService/Export',
        request,
        metadata || {},
        this.methodDescriptorExport,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/order.OrderService/Export',
    request,
    metadata || {},
    this.methodDescriptorExport);
  }

  methodDescriptorCancel = new grpcWeb.MethodDescriptor(
    '/order.OrderService/Cancel',
    grpcWeb.MethodType.UNARY,
    protobuf_order_order_pb.CancelOrdersRequest,
    protobuf_order_order_pb.CancelOrdersResponse,
    (request: protobuf_order_order_pb.CancelOrdersRequest) => {
      return request.serializeBinary();
    },
    protobuf_order_order_pb.CancelOrdersResponse.deserializeBinary
  );

  cancel(
    request: protobuf_order_order_pb.CancelOrdersRequest,
    metadata: grpcWeb.Metadata | null): Promise<protobuf_order_order_pb.CancelOrdersResponse>;

  cancel(
    request: protobuf_order_order_pb.CancelOrdersRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: protobuf_order_order_pb.CancelOrdersResponse) => void): grpcWeb.ClientReadableStream<protobuf_order_order_pb.CancelOrdersResponse>;

  cancel(
    request: protobuf_order_order_pb.CancelOrdersRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: protobuf_order_order_pb.CancelOrdersResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/order.OrderService/Cancel',
        request,
        metadata || {},
        this.methodDescriptorCancel,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/order.OrderService/Cancel',
    request,
    metadata || {},
    this.methodDescriptorCancel);
  }

  methodDescriptorGetLastOrderForClientAndExporter = new grpcWeb.MethodDescriptor(
    '/order.OrderService/GetLastOrderForClientAndExporter',
    grpcWeb.MethodType.UNARY,
    protobuf_order_order_pb.GetLastOrderForClientAndExporterRequest,
    protobuf_model_merged_pb.OrderResponse,
    (request: protobuf_order_order_pb.GetLastOrderForClientAndExporterRequest) => {
      return request.serializeBinary();
    },
    protobuf_model_merged_pb.OrderResponse.deserializeBinary
  );

  getLastOrderForClientAndExporter(
    request: protobuf_order_order_pb.GetLastOrderForClientAndExporterRequest,
    metadata: grpcWeb.Metadata | null): Promise<protobuf_model_merged_pb.OrderResponse>;

  getLastOrderForClientAndExporter(
    request: protobuf_order_order_pb.GetLastOrderForClientAndExporterRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: protobuf_model_merged_pb.OrderResponse) => void): grpcWeb.ClientReadableStream<protobuf_model_merged_pb.OrderResponse>;

  getLastOrderForClientAndExporter(
    request: protobuf_order_order_pb.GetLastOrderForClientAndExporterRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: protobuf_model_merged_pb.OrderResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/order.OrderService/GetLastOrderForClientAndExporter',
        request,
        metadata || {},
        this.methodDescriptorGetLastOrderForClientAndExporter,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/order.OrderService/GetLastOrderForClientAndExporter',
    request,
    metadata || {},
    this.methodDescriptorGetLastOrderForClientAndExporter);
  }

  methodDescriptorGetExportersForClient = new grpcWeb.MethodDescriptor(
    '/order.OrderService/GetExportersForClient',
    grpcWeb.MethodType.UNARY,
    protobuf_order_order_pb.GetExportersForClientRequest,
    protobuf_order_order_pb.ListIDsResponse,
    (request: protobuf_order_order_pb.GetExportersForClientRequest) => {
      return request.serializeBinary();
    },
    protobuf_order_order_pb.ListIDsResponse.deserializeBinary
  );

  getExportersForClient(
    request: protobuf_order_order_pb.GetExportersForClientRequest,
    metadata: grpcWeb.Metadata | null): Promise<protobuf_order_order_pb.ListIDsResponse>;

  getExportersForClient(
    request: protobuf_order_order_pb.GetExportersForClientRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: protobuf_order_order_pb.ListIDsResponse) => void): grpcWeb.ClientReadableStream<protobuf_order_order_pb.ListIDsResponse>;

  getExportersForClient(
    request: protobuf_order_order_pb.GetExportersForClientRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: protobuf_order_order_pb.ListIDsResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/order.OrderService/GetExportersForClient',
        request,
        metadata || {},
        this.methodDescriptorGetExportersForClient,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/order.OrderService/GetExportersForClient',
    request,
    metadata || {},
    this.methodDescriptorGetExportersForClient);
  }

  methodDescriptorUpdateDispatchLoadingStatus = new grpcWeb.MethodDescriptor(
    '/order.OrderService/UpdateDispatchLoadingStatus',
    grpcWeb.MethodType.UNARY,
    protobuf_order_order_pb.UpdateDispatchLoadingStatusRequest,
    protobuf_model_merged_pb.OrderResponse,
    (request: protobuf_order_order_pb.UpdateDispatchLoadingStatusRequest) => {
      return request.serializeBinary();
    },
    protobuf_model_merged_pb.OrderResponse.deserializeBinary
  );

  updateDispatchLoadingStatus(
    request: protobuf_order_order_pb.UpdateDispatchLoadingStatusRequest,
    metadata: grpcWeb.Metadata | null): Promise<protobuf_model_merged_pb.OrderResponse>;

  updateDispatchLoadingStatus(
    request: protobuf_order_order_pb.UpdateDispatchLoadingStatusRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: protobuf_model_merged_pb.OrderResponse) => void): grpcWeb.ClientReadableStream<protobuf_model_merged_pb.OrderResponse>;

  updateDispatchLoadingStatus(
    request: protobuf_order_order_pb.UpdateDispatchLoadingStatusRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: protobuf_model_merged_pb.OrderResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/order.OrderService/UpdateDispatchLoadingStatus',
        request,
        metadata || {},
        this.methodDescriptorUpdateDispatchLoadingStatus,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/order.OrderService/UpdateDispatchLoadingStatus',
    request,
    metadata || {},
    this.methodDescriptorUpdateDispatchLoadingStatus);
  }

  methodDescriptorUpdateDispatchDeliveryStatus = new grpcWeb.MethodDescriptor(
    '/order.OrderService/UpdateDispatchDeliveryStatus',
    grpcWeb.MethodType.UNARY,
    protobuf_order_order_pb.UpdateDispatchDeliveryStatusRequest,
    protobuf_model_merged_pb.OrderResponse,
    (request: protobuf_order_order_pb.UpdateDispatchDeliveryStatusRequest) => {
      return request.serializeBinary();
    },
    protobuf_model_merged_pb.OrderResponse.deserializeBinary
  );

  updateDispatchDeliveryStatus(
    request: protobuf_order_order_pb.UpdateDispatchDeliveryStatusRequest,
    metadata: grpcWeb.Metadata | null): Promise<protobuf_model_merged_pb.OrderResponse>;

  updateDispatchDeliveryStatus(
    request: protobuf_order_order_pb.UpdateDispatchDeliveryStatusRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: protobuf_model_merged_pb.OrderResponse) => void): grpcWeb.ClientReadableStream<protobuf_model_merged_pb.OrderResponse>;

  updateDispatchDeliveryStatus(
    request: protobuf_order_order_pb.UpdateDispatchDeliveryStatusRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: protobuf_model_merged_pb.OrderResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/order.OrderService/UpdateDispatchDeliveryStatus',
        request,
        metadata || {},
        this.methodDescriptorUpdateDispatchDeliveryStatus,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/order.OrderService/UpdateDispatchDeliveryStatus',
    request,
    metadata || {},
    this.methodDescriptorUpdateDispatchDeliveryStatus);
  }

  methodDescriptorApproveByBroker = new grpcWeb.MethodDescriptor(
    '/order.OrderService/ApproveByBroker',
    grpcWeb.MethodType.UNARY,
    protobuf_order_order_pb.ApproveByBrokerRequest,
    protobuf_model_merged_pb.OrderResponse,
    (request: protobuf_order_order_pb.ApproveByBrokerRequest) => {
      return request.serializeBinary();
    },
    protobuf_model_merged_pb.OrderResponse.deserializeBinary
  );

  approveByBroker(
    request: protobuf_order_order_pb.ApproveByBrokerRequest,
    metadata: grpcWeb.Metadata | null): Promise<protobuf_model_merged_pb.OrderResponse>;

  approveByBroker(
    request: protobuf_order_order_pb.ApproveByBrokerRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: protobuf_model_merged_pb.OrderResponse) => void): grpcWeb.ClientReadableStream<protobuf_model_merged_pb.OrderResponse>;

  approveByBroker(
    request: protobuf_order_order_pb.ApproveByBrokerRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: protobuf_model_merged_pb.OrderResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/order.OrderService/ApproveByBroker',
        request,
        metadata || {},
        this.methodDescriptorApproveByBroker,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/order.OrderService/ApproveByBroker',
    request,
    metadata || {},
    this.methodDescriptorApproveByBroker);
  }

  methodDescriptorSetCheckedDocType = new grpcWeb.MethodDescriptor(
    '/order.OrderService/SetCheckedDocType',
    grpcWeb.MethodType.UNARY,
    protobuf_order_order_pb.SetCheckedDocTypeRequest,
    protobuf_model_merged_pb.OrderItemResponse,
    (request: protobuf_order_order_pb.SetCheckedDocTypeRequest) => {
      return request.serializeBinary();
    },
    protobuf_model_merged_pb.OrderItemResponse.deserializeBinary
  );

  setCheckedDocType(
    request: protobuf_order_order_pb.SetCheckedDocTypeRequest,
    metadata: grpcWeb.Metadata | null): Promise<protobuf_model_merged_pb.OrderItemResponse>;

  setCheckedDocType(
    request: protobuf_order_order_pb.SetCheckedDocTypeRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: protobuf_model_merged_pb.OrderItemResponse) => void): grpcWeb.ClientReadableStream<protobuf_model_merged_pb.OrderItemResponse>;

  setCheckedDocType(
    request: protobuf_order_order_pb.SetCheckedDocTypeRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: protobuf_model_merged_pb.OrderItemResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/order.OrderService/SetCheckedDocType',
        request,
        metadata || {},
        this.methodDescriptorSetCheckedDocType,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/order.OrderService/SetCheckedDocType',
    request,
    metadata || {},
    this.methodDescriptorSetCheckedDocType);
  }

  methodDescriptorListHistory = new grpcWeb.MethodDescriptor(
    '/order.OrderService/ListHistory',
    grpcWeb.MethodType.UNARY,
    protobuf_order_order_pb.ListHistoryRequest,
    protobuf_history_history_pb.ListHistoryResponse,
    (request: protobuf_order_order_pb.ListHistoryRequest) => {
      return request.serializeBinary();
    },
    protobuf_history_history_pb.ListHistoryResponse.deserializeBinary
  );

  listHistory(
    request: protobuf_order_order_pb.ListHistoryRequest,
    metadata: grpcWeb.Metadata | null): Promise<protobuf_history_history_pb.ListHistoryResponse>;

  listHistory(
    request: protobuf_order_order_pb.ListHistoryRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: protobuf_history_history_pb.ListHistoryResponse) => void): grpcWeb.ClientReadableStream<protobuf_history_history_pb.ListHistoryResponse>;

  listHistory(
    request: protobuf_order_order_pb.ListHistoryRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: protobuf_history_history_pb.ListHistoryResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/order.OrderService/ListHistory',
        request,
        metadata || {},
        this.methodDescriptorListHistory,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/order.OrderService/ListHistory',
    request,
    metadata || {},
    this.methodDescriptorListHistory);
  }

  methodDescriptorListHistoryS = new grpcWeb.MethodDescriptor(
    '/order.OrderService/ListHistoryS',
    grpcWeb.MethodType.UNARY,
    protobuf_order_order_pb.ListHistoryRequest,
    protobuf_history_history_pb.ListHistorySResponse,
    (request: protobuf_order_order_pb.ListHistoryRequest) => {
      return request.serializeBinary();
    },
    protobuf_history_history_pb.ListHistorySResponse.deserializeBinary
  );

  listHistoryS(
    request: protobuf_order_order_pb.ListHistoryRequest,
    metadata: grpcWeb.Metadata | null): Promise<protobuf_history_history_pb.ListHistorySResponse>;

  listHistoryS(
    request: protobuf_order_order_pb.ListHistoryRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: protobuf_history_history_pb.ListHistorySResponse) => void): grpcWeb.ClientReadableStream<protobuf_history_history_pb.ListHistorySResponse>;

  listHistoryS(
    request: protobuf_order_order_pb.ListHistoryRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: protobuf_history_history_pb.ListHistorySResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/order.OrderService/ListHistoryS',
        request,
        metadata || {},
        this.methodDescriptorListHistoryS,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/order.OrderService/ListHistoryS',
    request,
    metadata || {},
    this.methodDescriptorListHistoryS);
  }

  methodDescriptorUpdateContract = new grpcWeb.MethodDescriptor(
    '/order.OrderService/UpdateContract',
    grpcWeb.MethodType.UNARY,
    protobuf_order_order_pb.UpdateContractRequest,
    protobuf_model_merged_pb.OrderContractResponse,
    (request: protobuf_order_order_pb.UpdateContractRequest) => {
      return request.serializeBinary();
    },
    protobuf_model_merged_pb.OrderContractResponse.deserializeBinary
  );

  updateContract(
    request: protobuf_order_order_pb.UpdateContractRequest,
    metadata: grpcWeb.Metadata | null): Promise<protobuf_model_merged_pb.OrderContractResponse>;

  updateContract(
    request: protobuf_order_order_pb.UpdateContractRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: protobuf_model_merged_pb.OrderContractResponse) => void): grpcWeb.ClientReadableStream<protobuf_model_merged_pb.OrderContractResponse>;

  updateContract(
    request: protobuf_order_order_pb.UpdateContractRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: protobuf_model_merged_pb.OrderContractResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/order.OrderService/UpdateContract',
        request,
        metadata || {},
        this.methodDescriptorUpdateContract,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/order.OrderService/UpdateContract',
    request,
    metadata || {},
    this.methodDescriptorUpdateContract);
  }

  methodDescriptorListContracts = new grpcWeb.MethodDescriptor(
    '/order.OrderService/ListContracts',
    grpcWeb.MethodType.UNARY,
    protobuf_order_order_pb.ListContractsRequest,
    protobuf_order_order_pb.ListContractsResponse,
    (request: protobuf_order_order_pb.ListContractsRequest) => {
      return request.serializeBinary();
    },
    protobuf_order_order_pb.ListContractsResponse.deserializeBinary
  );

  listContracts(
    request: protobuf_order_order_pb.ListContractsRequest,
    metadata: grpcWeb.Metadata | null): Promise<protobuf_order_order_pb.ListContractsResponse>;

  listContracts(
    request: protobuf_order_order_pb.ListContractsRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: protobuf_order_order_pb.ListContractsResponse) => void): grpcWeb.ClientReadableStream<protobuf_order_order_pb.ListContractsResponse>;

  listContracts(
    request: protobuf_order_order_pb.ListContractsRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: protobuf_order_order_pb.ListContractsResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/order.OrderService/ListContracts',
        request,
        metadata || {},
        this.methodDescriptorListContracts,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/order.OrderService/ListContracts',
    request,
    metadata || {},
    this.methodDescriptorListContracts);
  }

}

